.contact-container {
  font-size: 19px;

  .contact-header-container {
    text-align: center;
    margin-bottom: 60px;

    .contact-title {
      font-size: var(--title-font-size-mobile);
      font-weight: 400;
    }

    .contact-subtitle {
      font-size: var(--subtitle-font-size-mobile);
    }
  }

  .form-container {
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    .form-controls {
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;
      width: 90%;

      > div {
        width: 100%;
      }
    }

    .datenschutz-checkbox-container {
      padding: 0 5%;
      margin: 0;
    }

    .submit-button {
      width: 100%;
    }
  }

  .datenschutz-link {
    color: #0055aa;
  }

  .datenschutz-link:hover {
    color: #004d9a;
    text-decoration: underline;
  }
}

@media (min-width: 800px) {
  .contact-container {
    .contact-header-container {
      .contact-title {
        font-size: var(--title-font-size);
      }

      .contact-subtitle {
        font-size: var(--subtitle-font-size);
      }
    }

    .form-container {
      .form-controls {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: start;
        justify-items: start;
      }

      .datenschutz-checkbox-container {
        padding: unset;
      }

      .submit-button {
        width: 200px;
      }
    }
  }
}

@media (min-width: 1100px) {
  .contact-container {
    .form-container {
      .form-controls {
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        justify-content: start;
        justify-items: start;
      }
    }
  }
}
